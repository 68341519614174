var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            closable: true,
            size: "normal",
            title: _vm.modalTitle,
            "ok-text": "保存",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "离职员工", prop: "onJobId" } },
                [
                  _c("perTreeSelect", {
                    ref: "perTree",
                    attrs: {
                      disabled: !_vm.isAdd,
                      "per-tree-data": _vm.getCanLeaveStaff,
                    },
                    model: {
                      value: _vm.form.onJobId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "onJobId", $$v)
                      },
                      expression: "form.onJobId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "计划离职日期", prop: "planLeaveDate" } },
                [
                  _c("a-date-picker", {
                    attrs: {
                      disabled: !_vm.isAdd,
                      "value-format": "YYYY-MM-DD",
                      placeholder: "请选择时间",
                    },
                    model: {
                      value: _vm.form.planLeaveDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "planLeaveDate", $$v)
                      },
                      expression: "form.planLeaveDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "离职方式", prop: "handleType" } },
                [
                  _c("dictionariesInput", {
                    attrs: { disabled: !_vm.isAdd, parameter: "handle_type" },
                    model: {
                      value: _vm.form.handleType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "handleType", $$v)
                      },
                      expression: "form.handleType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "离职原因", prop: "leaveReasons" } },
                [
                  _c("dictionariesInput", {
                    attrs: { disabled: !_vm.isAdd, parameter: "leave_reasons" },
                    model: {
                      value: _vm.form.leaveReasons,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "leaveReasons", $$v)
                      },
                      expression: "form.leaveReasons",
                    },
                  }),
                ],
                1
              ),
              _vm.isAdd
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "离职备注", prop: "remark" } },
                    [
                      _c("a-input", {
                        attrs: {
                          type: "textarea",
                          "auto-size": true,
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "a-form-model-item",
                    { attrs: { label: "离职日期", prop: "newplanLeaveDate" } },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "value-format": "YYYY-MM-DD",
                          placeholder: "请选择时间",
                        },
                        model: {
                          value: _vm.form.newplanLeaveDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "newplanLeaveDate", $$v)
                          },
                          expression: "form.newplanLeaveDate",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }